function numberWithCommas(number) {
  var parts = number.toString().split(".");
  parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,".");
  return parts.join(",");
}

function numberToRealSymbolAndCommas(number) {
  number = parseFloat(number).toFixed(2);
  number = "R$ " + numberWithCommas(number);

  if(number.includes("-")) {
    number = number.split("-").join("");
    number = "- " + number;
  }

  return number;
}

function valueWithRealSymbolToNumber(value) {
  if(value == null) return 0.00;

  let startSubStrIndex = 3;
  let isNagativeNumber = false;

  if(value.includes("-")) {
    startSubStrIndex = 5;
    isNagativeNumber = true;
  }
  
  value = value.substr(startSubStrIndex, value.length);
  value = value.split(".").join("");
  value = value.replace(",", ".");

  value = parseFloat(value);

  if (isNagativeNumber) {
    value = value - (value * 2);
  }

  return value;
}

function brlToFloat(brlString) {
  // Remove the "R$", spaces and any leading/trailing whitespace
  let cleanedString = brlString
    .replace(/[^\d,-]/g, '') // Remove everything except digits, comma, and hyphen
    .replace(/,/g, '.')      // Replace comma with dot (to handle decimal)
    .trim();                 // Trim any whitespace

  // Check if the cleaned string is a valid number
  const number = parseFloat(cleanedString);

  // Return the converted number
  if (isNaN(number)) {
    throw new Error("Invalid currency format");
  }
  
  return number;
}

function floatToBrl(value, showSymbol = true) {
  // Ensure the value is a number
  if (isNaN(value)) {
    throw new Error("Invalid number");
  }

  // Format the number based on whether the symbol should be included
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  // If showSymbol is true, include currency formatting
  if (showSymbol) {
    options.style = 'currency';
    options.currency = 'BRL';
    return value.toLocaleString('pt-BR', options);
  } else {
    // If showSymbol is false, just format the number
    return value.toLocaleString('pt-BR', { ...options, style: 'decimal' });
  }
}

export default {
  brlToFloat,
  floatToBrl
};