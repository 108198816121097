import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["postalCode", "street", "city", "stateAbbreviation"]

  connect() {
    this.postalCodeTarget.addEventListener("input", this.updateAddress.bind(this))
  }

  async updateAddress() {
    const postalCode = this.postalCodeTarget.value.replace(/\D/g, "")

    if (postalCode.length === 8) {
      try {
        const response = await fetch(`/postal_codes/${postalCode}`)
        const data = (await response.json()).data

        if (response.ok) {
        this.streetTarget.value = data.logradouro || ""
        this.cityTarget.value = data.localidade || ""
        this.stateAbbreviationTarget.value = data.uf || ""

        this.setReadOnly(true)
        } else {
          this.setReadOnly(false)
        }
      } catch (error) {
        console.log("Error fetching address", error);

        this.setReadOnly(false)
      }
    }
  }

  setReadOnly(isReadOnly) {
    this.streetTarget.readOnly = isReadOnly
    this.cityTarget.readOnly = isReadOnly
    this.stateAbbreviationTarget.readOnly = isReadOnly
  }
}