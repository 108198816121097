import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addItem", "template"]

  addAssociation(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().valueOf())
    this.addItemTarget.insertAdjacentHTML("beforebegin", content)

    const newElement = this.addItemTarget.previousElementSibling
    const inputsWithDataMaskName = this.getInputElementsWithAttribute(newElement, "data-mask-name")
    const dataMaskNames = this.extractAttributeValuesToSet(inputsWithDataMaskName, "data-mask-name")
    this.applyMasksFromSet(dataMaskNames, initializeMaskPattern)

    const inputsWithDataInputMask = this.getInputElementsWithAttribute(newElement, "data-input-mask-name")
    const dataInputMaskNames = this.extractAttributeValuesToSet(inputsWithDataInputMask, "data-input-mask-name")
    this.applyInputMasksFromSet(dataInputMaskNames)
  }

  removeAssociation(event) {
    event.preventDefault()

    if (!confirm("Realmente deseja remover?")) {
      return
    }

    var item = event.target.closest(".nested-fields")
    item.remove();
    // item.querySelector("input[name*='_destroy']").value = 1
    // item.style.display = "none"
  }

  getInputElementsWithAttribute(element, attribute) {
    return Array.from(element.querySelectorAll(`input[${attribute}]`));
  }
  
  extractAttributeValuesToSet(elements, attribute) {
    const attributeSet = new Set();
  
    elements.forEach(input => {
      attributeSet.add(input.getAttribute(attribute));
    });
    
    return attributeSet;
  }
  
  applyMasksFromSet(attributeSet, reinitializeFunction) {
    attributeSet.forEach(name => {
      reinitializeFunction(name);
    });
  }

  applyInputMasksFromSet(attributeSet) {
    attributeSet.forEach(name => {
      $(`.${name}`).each(function() {
        const maskPattern = $(this).data('inputmask');
        if (maskPattern) {
          Inputmask(maskPattern).mask(this);
        }
      });
    });
  }
}