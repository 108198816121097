import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["reference"]

  updateCopies(event) {
    const reference = event.target
    let value = reference.value

    this.copyValue(reference.dataset.copyFormReferenceName, value)
  }

  processValue(copy, value) {
    if (copy.dataset.copyFormNumericOnly === "true") {
      return value.replace(/\D/g, "")
    }

    return value
  }

  copyValue(copyFrom, value) {
    if (value) {
      const copies = this.element.querySelectorAll(`[data-copy-from="${copyFrom}"]`)
      copies.forEach(copy => {
        if (this.shouldCopy(copy)) {
          copy.value = this.processValue(copy, value)
        }
      })
    }
  }

  shouldCopy(copy) {
    const conditionKey = copy.dataset.copyFormConditionKey

    if (conditionKey) {
      const conditionInput = this.element.querySelector(
        `[data-copy-form-condition-key-target="${conditionKey}"]`
      )
      const expectedValue = copy.dataset.copyFormExpectedValue

      return conditionInput && conditionInput.value === expectedValue
    } else {
      return true
    }
  }
}