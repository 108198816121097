import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["kind", "number"]

  applyMask() {
    const kind = this.kindTarget.value

    if (kind === "cellular") {
      this.changeMask("cellular-phone-number-mask")
      initializeMaskPattern("cellular-phone-number-mask")
    } else {
      this.changeMask("residential-phone-number-mask")
      initializeMaskPattern("residential-phone-number-mask")
    }
  }

  change() {
    this.applyMask()
    this.clearNumber()
  }

  changeMask(newMaskName) {
    const oldMaskName = this.numberTarget.getAttribute("data-mask-name")
    this.numberTarget.classList.remove(oldMaskName)

    this.numberTarget.setAttribute("data-mask-name", newMaskName)
    this.numberTarget.classList.add(newMaskName)
  }

  clearNumber() {
    this.numberTarget.value = ""
  }
}