import { Controller } from "stimulus";
import currencyUtils from "../../assets/static_js/currency"

export default class extends Controller {
  updateTotal() {
    let total = 0

    const amounts = document.querySelectorAll(".sum_form_amount")
    amounts.forEach(amount => {
      total += currencyUtils.brlToFloat(amount.value) || 0
    })

    const totalAmounts = document.querySelectorAll(".sum_form_total_amount")
    totalAmounts.forEach(totalAmount => {
      totalAmount.value = currencyUtils.floatToBrl(total, false)
    })
  }
}